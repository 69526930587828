<template>
  <div id="app">
    <Nav :pt="isIndex" />
    <div class="pageMain">
       <div class="p20 boxText">
         <img :src="require(`@/assets/consultant/consul-01${$i18n.locale == 'zh'?'z':'e'}.png`)" width="160"/>
         <div class="pt20" id="3"><img :src="require(`@/assets/consultant/consul-02${$i18n.locale == 'zh'?'z.jpg':'e.png'}`)" /></div>
         <div class="pt10" v-if="$i18n.locale == 'zh'">
           著名作曲家，1970年毕业于西安音乐学院作曲系，1978年入中央音乐学院作曲系进修，是国务院有突出贡献的专家、全国文化系统先进工作者，中国音乐家协会名誉主席。曾任中国文学艺术界联合会第十届荣誉委员，中国音乐家协会主席，陕西省文学艺术界联合会主席，陕西省歌舞剧院院长，陕西省戏曲研究院副院长，陕西省电影音乐学会会长，西安音乐学院院长。
         </div>
          <div class="pt10" v-else>
            Mr. Zhao Jiping is a famous composer who graduated from the Composition Department of Xi'an Conservatory of Music in 1970. He furthered his studies at the Composition Department of Central Conservatory of Music in 1978, and is an expert with outstanding contributions assessed by the State Council as well as an advanced worker of the national cultural system and Honorary Chairman of Chinese Musicians Association. He once served as a 10th honorary member of Chinese Federation of Literary and Art Circles, Chairman of Chinese Musicians Association, Chairman of Shaanxi Federation of Literary and Art Circles, Director of Shaanxi Song and Dance Theatre, Vice President of Shaanxi Opera Research Institute, President of Shaanxi Society of Film and Music, and Dean of Xi'an Conservatory of Music.
          </div>
         <div class="pt20" id="4"><img :src="require(`@/assets/consultant/consul-03${$i18n.locale == 'zh'?'z.jpg':'e.png'}`)" /></div>
         <div class="pt10" v-if="$i18n.locale == 'zh'">
           第十届、十一届全国政协委员，国家级优秀专家，享受国务院特殊津贴，中国文联德艺双馨艺术家。现任中国民族管弦乐学会会长、中国歌剧研究会副主席。曾任黑龙江省文化厅副厅长、中央歌剧院院长等职务。
         </div>
        <div class="pt10" v-else>
            Mr. Liu Xijin is a member of the 10th and 11th national committee of CPPCC, a national outstanding expert enjoying special allowance by the State Council, as well as an artist with both professional skills and moral integrity recognised by the China Federation of Literary and Art Circles. Currently, he is President of China Nationalities Orchestra Society and Vice President of China Opera Research Society. He used to serve as Deputy Director of Culture Department of Heilongjiang Province and Director of China National Opera House.
          </div>
         <div class="pt20" id="5"><img :src="require(`@/assets/consultant/consul-04${$i18n.locale == 'zh'?'z.jpg':'e.png'}`)" /></div>
         <div class="pt10" v-if="$i18n.locale == 'zh'">
          民族音乐活动家，中国广播艺术团原团长、党委书记，中国民族管弦乐学会第五届副会长，第六届副会长兼秘书长。曾多次成功策划并率中国广播艺术团属下的中国广播民族乐团、曲艺团、交响乐团赴海内外巡演数百场，受到热烈欢迎。
         </div>
         <div class="pt10" v-else>
           Mr. Wang Shuwei is a national music activist, former President and Secretary of the Party Committee of China Broadcasting and Art Troupe, 5th Vice President and 6th Vice President cum Secretary- General of China Nationalities Orchestra Society. He has successfully planned and led China Broadcasting Chinese Orchestra, Chinese folk art troupe and symphony orchestra under the China Broadcasting and Art Troupe to carry out hundreds of performing tours at home and abroad, which were warmly welcomed.
         </div>
         <div class="pt20" id="6"><img :src="require(`@/assets/consultant/consul-05${$i18n.locale == 'zh'?'z.jpg':'e.png'}`)" /></div>
         <div class="pt10" v-if="$i18n.locale == 'zh'">
           国家一级作曲。历任上海民族乐团团长、艺术总监，中国民族管弦乐学会副会长，中国音乐家协会理事，民族音乐委员会副主任，中国民族管弦乐学会荣誉会长。享受中国国务院颁发的政府特殊津贴。
         </div>
         <div class="pt10" v-else>
           Mr. Gu Guanren is a national first-grade composer who has served successively as President and Artistic Director of Shanghai Chinese Orchestra, Vice President of China Nationalities Orchestra Society, Director of Chinese Musicians Association, Vice Director of National Music Committee, and Honorary President of China Nationalities Orchestra Society. He enjoys the special government allowance granted by the State Council of China.
         </div>
       </div>

      <Footer />

    </div>
    
  </div>
</template>

<script>
import Nav from '@/components/nav.vue'
import Footer from '@/components/footer.vue'
export default {
  
  name: 'App',
  components: {
    Nav,
    Footer
  },
  data () {
    return {
      isIndex: false,
      joinList:[
        {
          title:"招聘岗位名称1",
          othe:"时间/地点",
          describe:"苏州民族管弦乐团是由苏州市和高新区共同成立的职业乐团，为市级公益性文艺团体。乐团由曾经十三次赴维也纳金色大厅指挥的著名指挥家彭家鹏任艺术总监兼首席指挥，由著名二胡演奏家、作曲家朱昌耀任艺术指导。聘请中国音乐家协会名誉主席、著名作曲家赵季平，中国民族管弦乐学会会长、著名作曲家刘锡津，中国民族管弦乐学会副会长兼秘书长王书伟，中国民族管弦乐学会荣誉会长、著名作曲家顾冠仁任艺术顾问。乐团拥有演奏员九十余人，由海内外知名演奏家及中央、上海等九大音乐学院的优秀毕业生组成。",
          salary:"带薪年假，十四薪",
          show:true
        },{
          title:"招聘岗位名称2",
          othe:"时间/地点",
          describe:"苏州民族管弦乐团是由苏州市和高新区、著名作曲家顾冠仁任艺术顾问。乐团拥有演奏员九十余人，由海内外知名演奏家及中央、上海等九大音乐学院的优秀毕业生组成。",
          salary:"带薪年假，十四薪",
          show:false
        },{
          title:"招聘岗位名称3",
          othe:"时间/地点",
          describe:"乐团拥有演奏员九十余人，由海内外知名演奏家及中央、上海等九大音乐学院的优秀毕业生组成。",
          salary:"带薪年假，十四薪",
          show:false
        }
      ]
    }
  },
  computed: {
      
  },
  mounted() {
    
  },
  methods:{
    joinView(index){
      this.joinList[index].show = !this.joinList[index].show
    }
    // getCompanys(){
      
    // }
    
  },
}
</script>

<style>
.pageMain img{
  max-width: 100%;
}
</style>

